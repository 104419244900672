import React from 'react';
import Head from 'next/head';
import { getCookie } from 'cookies-next';
import { Button } from '@components/atoms/Button/Button';
import { Link } from '@components/atoms/Link/Link';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { DefaultFullWidth } from '@components/templates/layouts/DefaultFullWidth';
import { useTagManager } from '@lib/hooks/useTagManager';
import { IconSearch } from '@assets/icon-search';

function ErrorPage() {
  const title = 'Wie bin ich hierhergekommen?';
  const description =
    'Diese Seite existiert nicht. Aber keine Angst, verlaufen hat sich hier noch niemand. Versuche dein Glück nochmals mit dem Suchfeld oder geh zurück auf die Startseite.';
  const meta = {
    meta_title: '404 - Seite nicht gefunden',
    open_graph_image: '/assets/tvm_assets/og-image-tvmedia.jpg',
  };

  useTagManager({
    seitentyp: 'Error',
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    oewaSeitenkontingent: 'Service',
    oewaSubkontingent: 'Sonstiges',
    oewaProfilingkontingent: 'Sonstiges',
    cookieAlert: getCookie('OptanonConsent'),
  });

  return (
    <>
      <Head>{Meta(meta.open_graph_image, meta.meta_title)}</Head>
      <main className="grid min-h-[50vh] items-center portrait-giant:min-h-[640px]">
        <Oewa pageKey="Service" categoryKey="Sonstiges" profileKey="Sonstiges" />
        <div className="mx-auto grid max-w-screen-2xl gap-6 lg:grid-cols-2 lg:px-16 xl:px-32">
          <div className="min-w-0 basis-1/2 p-8 pt-32">
            <Title level={1} classProps={{ heading: 'hyphenate font-herokid text-4xl lg:text-5xl text-white' }}>
              {title}
            </Title>
            <p className="hyphenate p-3 text-xl text-white">{description}</p>
            <Button title="Zur Startseite" link="/" buttonStyle="primary" classProps={{ root: 'mt-6' }} />
          </div>
          <div className={'relative grid aspect-square min-w-0 max-w-lg basis-1/2 items-end self-end'}>
            <video autoPlay muted loop playsInline>
              <source src="/assets/tvm_assets/ConfusedTravolta.webm" type="video/webm" />
              <source src="/assets/tvm_assets/ConfusedTravolta.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className={'flex h-24 w-full items-center justify-center border border-gray-650/65 bg-gray-820/65'}>
          <Link
            href="/suche"
            className={
              'group flex h-11 w-64 cursor-pointer items-center justify-between rounded-full border border-gray-500/65 px-4'
            }
          >
            Suchen
            <span>
              <IconSearch
                classProps={{ root: 'w-3 h-3 md:w-4 md:h-4 group-hover:mr-1 transition-spacings duration-150' }}
              />
            </span>
          </Link>
        </div>
      </main>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {},
  };
}

ErrorPage.PageLayout = DefaultFullWidth;

export default ErrorPage;
