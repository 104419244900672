import React, { PropsWithChildren } from 'react';
import { Heading, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface TitleProps extends PropsWithChildren, PropsWithClassProps<'heading'> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

export const Title = ({ children, level, classProps }: TitleProps) => {
  let className = twMerge('font-herokid leading-header lg:leading-small', classProps?.heading);

  switch (level) {
    case 1:
      className = twMerge('text-4xl lg:text-5xl', className);
      break;
    case 2:
      className = twMerge('text-3xl lg:text-4xl', className);
      break;
    case 3:
      className = twMerge('text-2xl lg:text-3xl', className);
      break;
    case 4:
      className = twMerge('text-xl lg:text-2xl', className);
      break;
    case 5:
      className = twMerge('text-base lg:text-xl', className);
      break;
    case 6:
      className = twMerge('text-sm lg:text-base', className);
      break;
  }

  return (
    <Heading level={level} classProps={{ root: className }}>
      {children}
    </Heading>
  );
};
